import React from "react";
import Image from "../image";
import { Stack, Typography } from "@mui/material";

export default function ApplicationLogo({ sx = {}, ...others }) {
    return (
        <Stack sx={sx} {...others}>
            <Image
                src="/assets/logo/upcare-logo.png"
                sx={{ width: 300 }}
                imgSx={{ objectFit: "contain" }}
                alt="Pinoy Care"
            />
        </Stack>
    );
}

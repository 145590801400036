import { useEffect } from "react";
import { usePage } from "@inertiajs/react";
import { useSnackbar } from "notistack";
import { Container, Stack } from "@mui/material";

export default function Guest({ sx = {}, size = "sm", children }) {
    const { flash } = usePage().props;
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (flash.message) {
            enqueueSnackbar(flash.message, {
                variant: flash.type || "default",
            });
            flash.message = null;
        }
    }, [flash]);

    return (
        <Container maxWidth={size} sx={{ minHeight: "100vh", display: "flex" }}>
            <Stack sx={{ ...sx, width: 1 }}>{children}</Stack>
        </Container>
    );
}
